import { watchEffect } from "vue";
import {
  createRouter,
  createWebHistory,
  type RouteLocation,
  type RouteMeta,
} from "vue-router";
import { getCurrentUser } from "vuefire";
import { useCompanyStore } from "@/stores/company";
import { useUserStore } from "../stores/user.js";
import { ErrorContext, ErrorType, captureError } from "@/utils/errors/index.js";
import { ab, ActionKey, actionTypeMap } from "@/services/casl";
import type { User as DbUser } from "@/models/user.model.js";
import type { User } from "firebase/auth";
import { i18ng } from "@/i18n";

const checkAllowed = (meta: RouteMeta): boolean => {
  const rolesNeeded = meta.role ? [meta.role] : meta.roles ?? [];
  if (rolesNeeded.length) {
    const can = rolesNeeded.some((roleNeeded) => {
      return ab.can(
        actionTypeMap[roleNeeded.action],
        [roleNeeded.category, roleNeeded.subcategory]
          .filter((x) => x)
          .join("-"),
      );
    });
    return can;
  }
  return true;
};

const checkAllowedInChildren = (to: RouteLocation, rootName: string) => {
  const children = to.matched
    .find((x) => x.name === rootName)
    ?.children.filter((x) => x.name !== to.name);

  if (children) {
    for (const child of children) {
      if (child.meta) {
        const can = checkAllowed(child.meta);
        if (can) return { name: child.name };
      }
    }
  }

  return { name: "unauthorized" };
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "layout",
      component: () => import("../layouts/layout.vue"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("../views/Home.vue"),
        },
        {
          path: "/login",
          name: "login",
          component: () => import("../views/auth/LoginView.vue"),
          meta: {
            title: "router.login",
            requiresAuth: false,
          },
        },
        {
          path: "/password-reset",
          name: "password-reset",
          component: () => import("../views/auth/ResetPasswordView.vue"),
          meta: {
            title: "router.passwordReset",
            passwordReset: true,
            requiresAuth: false,
          },
        },
        {
          path: "/asuser",
          name: "asuser",
          component: () => import("../views/auth/AsUserView.vue"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/signup",
          name: "signup",
          component: () => import("../views/auth/SignupView.vue"),
          meta: {
            title: "router.signUp",
            requiresAuth: false,
          },
        },
        {
          path: "/locales",
          name: "locales",
          component: () => import("../views/LocalesView.vue"),
          meta: {
            title: "router.locales",
          },
        },
        {
          path: "/logout",
          name: "logout",
          component: () => import("../views/auth/LogoutView.vue"),
        },
        {
          path: "/switch-company",
          name: "companyOverview",
          component: () => import("../views/admin/CompanyListView.vue"),
          meta: {
            title: "router.switchCompany",
          },
        },
        {
          path: "/crops",
          name: "crops-overview",
          // route level code-splitting
          // this generates a separate chunk (cropsOverview.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import("../views/crop/CropListView.vue"),
          meta: {
            title: "router.cropsOverview",
            role: {
              category: "cultivationOverview",
              subcategory: "all",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("../views/dashboard/DashboardView.vue"),
          meta: {
            title: "router.dashboard",
            // role: null, // will always be allowed but redirect to / from within if no permissions present
          },
        },
        {
          path: "/summary",
          name: "summary",
          component: () => import("../views/dashboard/SummaryView.vue"),
          meta: {
            title: "router.summary",
            role: {
              category: "cropNotes",
              subcategory: "all",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/sensors",
          name: "sensors-overview",
          component: () => import("../views/sensors/Sensors.vue"),
          meta: {
            title: "router.sensors",
            role: {
              category: "sensors",
              subcategory: "all",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/lab",
          // name: "lab-analyses-oveerview",
          meta: {
            title: "router.lab",
          },
          children: [
            {
              path: "pending",
              name: "lab-analyses-pending",
              component: () => import("../views/lab/LabAnalysesView.vue"),
              meta: {
                role: {
                  category: "labAnalyses",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
            {
              path: "",
              name: "lab-analyses-overview",
              component: () => import("../views/lab/LabAnalysesView.vue"),
              meta: {
                role: {
                  category: "labAnalyses",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "complete",
              name: "lab-analyses-complete",
              component: () => import("../views/lab/LabAnalysesView.vue"),
              meta: {
                role: {
                  category: "labAnalyses",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
          ],
        },
        {
          path: "/notes",
          name: "notes-overview",
          component: () => import("../views/note/NotesView.vue"),
          meta: {
            title: "router.notesOverview",
            role: {
              category: "cropNotes",
              subcategory: "all",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/tasks",
          children: [
            {
              path: "",
              name: "tasks-overview",
              component: () => import("../views/tasks/TasksOverview.vue"),
              meta: {
                title: "router.tasksOverview",
                roles: [
                  {
                    category: "tasks",
                    subcategory: "all",
                    action: ActionKey.READ,
                  },
                  {
                    category: "tasks",
                    subcategory: "my",
                    action: ActionKey.READ,
                  },
                ],
              },
            },
            {
              path: "add",
              name: "tasks-add",
              component: () => import("../views/tasks/CreateTaskView.vue"),
              meta: {
                title: "router.addTask",
                role: {
                  category: "tasks",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
            {
              path: "edit/:id",
              name: "tasks-edit",
              props: true,
              component: () => import("../views/tasks/CreateTaskView.vue"),
              meta: {
                title: "router.editTask",
                role: {
                  category: "tasks",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
          ],
        },
        {
          path: "/protocols",
          children: [
            {
              path: "",
              name: "protocols-overview",
              component: () => import("../views/protocols/ProtocolsView.vue"),
              meta: {
                title: "router.protocolsOverview",
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "add",
              name: "protocols-add",
              component: () =>
                import("../views/protocols/ProtocolsEditView.vue"),
              meta: {
                title: "router.addProtocol",
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
            {
              path: "edit/:id",
              name: "protocols-edit",
              props: true,
              component: () =>
                import("../views/protocols/ProtocolsEditView.vue"),
              meta: {
                title: "router.editProtocol",
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
            {
              path: "duplicate/:id",
              name: "protocols-duplicate",
              props: true,
              component: () =>
                import("../views/protocols/ProtocolsEditView.vue"),
              meta: {
                title: "router.duplicateProtocol",
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.CREATE,
                },
              },
            },
          ],
        },
        {
          path: "/advices",
          name: "advices-overview",
          component: () => import("../views/advices/AdvicesView.vue"),
          meta: {
            title: "router.advicesOverview",
            role: {
              category: "general",
              subcategory: "advices",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/documents",
          name: "documents-overview",
          redirect: { name: "company-documents" },
          component: () => import("../views/documents/DocumentsView.vue"),
          children: [
            {
              path: "",
              name: "documents-overview-root",
              redirect: (to) =>
                checkAllowedInChildren(to, "documents-overview"),
              meta: {
                role: {
                  category: "general",
                  subcategory: "documents",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "company",
              name: "company-documents",
              component: () => import("../views/documents/Company.vue"),
              meta: {
                title: "router.companyDocuments",
                role: {
                  category: "general",
                  subcategory: "documents",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "platform",
              name: "platform-documents",
              component: () => import("../views/documents/Platform.vue"),
              meta: {
                title: "router.platformDocuments",
                role: {
                  category: "general",
                  subcategory: "documents",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "crop",
              name: "crop-documents",
              component: () => import("../views/documents/Crop.vue"),
              meta: {
                title: "router.cropDocuments",
                role: {
                  category: "general",
                  subcategory: "documents",
                  action: ActionKey.READ,
                },
              },
            },
          ],
        },
        {
          path: "/data-explorer",
          name: "data-explorer-overview",
          component: () => import("../views/data-explorer/NeoExplorer.vue"),
          meta: {
            title: "router.dataExplorer",
            role: {
              category: "dataExplorer",
              subcategory: "all",
              action: ActionKey.READ,
            },
          },
        },

        {
          path: "/climate",
          name: "climate-overview",
          component: () => import("../views/climate/ClimateView.vue"),
          meta: {
            title: "router.climateOverview",
            role: {
              category: "general",
              subcategory: "climateData",
              action: ActionKey.READ,
            },
          },
        },
        {
          path: "/crops/add",
          name: "crop-add",
          component: () => import("../views/crop/CropAdd.vue"),
          meta: {
            title: "router.addCrop",
            role: {
              category: "addCrop",
              subcategory: "all",
              action: ActionKey.CREATE,
            },
          },
        },
        {
          path: "/crops/edit/:cropId",
          name: "crop-edit",
          component: () => import("../views/crop/CropEdit.vue"),
          meta: {
            title: "router.editCrop",
            role: {
              category: "cropProfile",
              subcategory: "all",
              action: ActionKey.READ, // then checking inside whether can be edited
            },
          },
        },
        {
          path: "/crops/duplicate",
          name: "crop-duplicate",
          component: () => import("../views/crop/CropDuplicate.vue"),
          meta: {
            title: "router.duplicateCrop",
            role: {
              category: "addCrop",
              subcategory: "all",
              action: ActionKey.CREATE,
            },
          },
        },
        {
          path: "/crops/:cropId",
          name: "crop-detail",
          component: () => import("../views/crop/CropDetailBase.vue"),
          children: [
            {
              path: "",
              name: "crop-detail-root",
              redirect: (to) => checkAllowedInChildren(to, "crop-detail"),
            },
            {
              path: "eoc",
              name: "crop-eoc",
              component: () =>
                import("../components/crop/crop-details/tabs/CropEOC.vue"),
            },
            {
              path: "log",
              name: "crop-log",
              component: () =>
                import("../components/crop/crop-details/tabs/CropLog.vue"),
              meta: {
                role: {
                  category: "cropNotes",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "protocol",
              name: "crop-protocol",
              component: () =>
                import("../components/crop/crop-details/tabs/CropProtocol.vue"),
              meta: {
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "lab",
              name: "crop-lab-analyses",
              component: () =>
                import("../components/crop/crop-details/tabs/CropLab.vue"),
              meta: {
                role: {
                  category: "labAnalyses",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "advices",
              name: "crop-advices",
              component: () =>
                import("../components/crop/crop-details/tabs/CropAdvices.vue"),
              meta: {
                role: {
                  category: "general",
                  subcategory: "advices",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "documents",
              name: "crop-docs",
              component: () =>
                import("../components/crop/crop-details/tabs/CropDocs.vue"),
              meta: {
                role: {
                  category: "general",
                  subcategory: "documents",
                  action: ActionKey.READ,
                },
              },
            },
          ],
        },
        {
          path: "/notes/add",
          props: true,
          name: "note-add",
          component: () => import("../views/note/NoteAdd.vue"),
          meta: {
            title: "router.addNote",
            role: {
              category: "cropNotes",
              subcategory: "all",
              action: ActionKey.CREATE,
            },
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("../views/settings/Settings.vue"),
          children: [
            {
              path: "",
              name: "settings-root",
              redirect: (to) => checkAllowedInChildren(to, "settings"),
            },
            {
              path: "company",
              name: "company-settings",
              component: () => import("../views/settings/Company.vue"),
              meta: {
                title: "router.companySettings",
                role: {
                  category: "companyProfile",
                  subcategory: "profile",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "crops",
              name: "crops-settings",
              component: () => import("../views/settings/Crops.vue"),
              meta: {
                title: "router.cropsSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "cropSettings",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "pdf-export",
              name: "pdf-export-settings",
              component: () => import("../views/settings/PdfExport.vue"),
              meta: {
                title: "router.pdfExportSetings",
                role: {
                  category: "companyProfile",
                  subcategory: "pdfSettings",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "substrates",
              name: "company-substrates",
              component: () => import("../views/settings/Substrates.vue"),
              meta: {
                title: "router.substratesSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "substrates",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "sensor",
              name: "sensor-settings",
              component: () => import("../views/settings/Sensor.vue"),
              meta: {
                title: "router.sensorSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "sensorSettings",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "location",
              name: "location-settings",
              component: () => import("../views/settings/Location.vue"),
              meta: {
                title: "router.locationSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "locationSettings",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "user-management",
              name: "user-management-settings",
              component: () => import("../views/settings/UserManagement.vue"),
              meta: {
                title: "router.userManagementSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "userManagement",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "notes",
              name: "notes-settings",
              component: () => import("../views/settings/Notes.vue"),
              meta: {
                title: "router.notesSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "noteChecklists",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "protocols",
              name: "protocols-settings",
              component: () => import("../views/settings/Protocols.vue"),
              meta: {
                title: "router.protocolsSettings",
                role: {
                  category: "cropProtocol",
                  subcategory: "all",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "tankmixes",
              name: "tankmixes-settings",
              component: () => import("../views/settings/TankMixes.vue"),
              meta: {
                title: "router.tankMixesSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "tankMixes",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "fertilization-bins",
              name: "fertilization-bins-settings",
              component: () =>
                import("../views/settings/FertilizationBins.vue"),
              meta: {
                title: "router.fertilizationBinsSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "fertilizationBins",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "climate-data",
              name: "climate-data-settings",
              component: () => import("../views/settings/ClimateData.vue"),
              meta: {
                title: "router.climateDataSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "climateData",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "climate-boxes",
              name: "climate-boxes-settings",
              component: () => import("../views/settings/ClimateBoxes.vue"),
              meta: {
                title: "router.climateBoxesSettings",
                role: {
                  category: "companyProfile",
                  subcategory: "climateBoxes",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "import-cultivation-data",
              name: "import-cultivation-data-settings",
              component: () => import("../views/settings/ImportExport.vue"),
              meta: {
                title: "router.importCultivationData",
                role: {
                  category: "companyProfile",
                  subcategory: "cropsImport",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "export-cultivation-data",
              name: "export-cultivation-data-settings",
              component: () =>
                import("../views/settings/ExportCultivationData.vue"),
              meta: {
                title: "router.exportCultivationData",
                role: {
                  category: "companyProfile",
                  subcategory: "exports",
                  action: ActionKey.READ,
                },
              },
            },
          ],
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("../views/profile/Profile.vue"),
          children: [
            {
              path: "company",
              name: "company-profile",
              component: () => import("../views/profile/Company.vue"),
              meta: {
                title: "router.companyProfile",
                role: {
                  category: "companyProfile",
                  subcategory: "profile",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "user",
              name: "user-profile",
              component: () => import("../views/profile/User.vue"),
              meta: {
                title: "router.userProfile",
              },
            },
          ],
        },
        {
          path: "/admin",
          name: "admin",
          redirect: { name: "companies-settings" },
          meta: {
            role: {
              category: "superadmin",
              subcategory: "",
              action: ActionKey.READ,
            },
          },
          children: [
            {
              path: "companies",
              name: "companies-settings",
              component: () => import("../views/admin/CompanyConfig.vue"),
              meta: {
                title: "router.companiesSettings",
                role: {
                  category: "superadmin",
                  subcategory: "",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "advisors",
              name: "advisors-settings",
              component: () => import("../views/admin/AdvisorConfig.vue"),
              meta: {
                title: "router.advisorsSettings",
                role: {
                  category: "superadmin",
                  subcategory: "",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "sensors",
              name: "sensors-settings",
              component: () => import("../views/admin/SensorConfig.vue"),
              meta: {
                title: "router.sensorSettings",
                role: {
                  category: "superadmin",
                  subcategory: "",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "admin-owners",
              name: "adminOwner-settings",
              component: () => import("../views/admin/AdminOwnerConfig.vue"),
              meta: {
                role: {
                  category: "superadmin",
                  subcategory: "",
                  action: ActionKey.READ,
                },
              },
            },
            {
              path: "roles",
              name: "roles-settings",
              component: () => import("../views/admin/RoleConfig.vue"),
              meta: {
                title: "router.rolesSettings",
                role: {
                  category: "superadmin",
                  subcategory: "",
                  action: ActionKey.READ,
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import("../views/Unauthorized.vue"),
      meta: {
        title: "router.unauthorized",
      },
    },
    {
      path: "/agreement",
      name: "agreement",
      component: () => import("../views/Agreement.vue"),
      meta: {
        title: "router.agreement",
      },
    },
  ],
});

const waitForUser = async () => {
  const store = useUserStore();
  let currentAuthUser: Awaited<ReturnType<typeof getCurrentUser>> =
    store.authUser;
  if (!currentAuthUser) {
    currentAuthUser = await getCurrentUser();
    store.setAuthUser(currentAuthUser);
  }

  if (!currentAuthUser) {
    store.reset();
    return Promise.resolve([null, null]);
  }

  return new Promise((resolve) => {
    if (store.$state.rulesLoaded) {
      resolve([currentAuthUser, store.$state.userInDb]);
    } else {
      watchEffect(() => {
        if (store.$state.rulesLoaded) {
          resolve([currentAuthUser, store.$state.userInDb]);
        }
      });
    }
  });
};

router.beforeResolve(async (to) => {
  // if route did not match redirect to '/'
  if (!to.name) {
    // redirect old QR codes from v2
    if (to.fullPath.startsWith("/user/crop-form")) {
      const cropIds = to.query.id;
      const locationIds =
        typeof to.query.locationIds === "string"
          ? to.query.locationIds.split(";")
          : [];
      const companyId = to.query.companyId ?? undefined;

      return { name: "note-add", query: { cropIds, locationIds, companyId } };
    }
    if (to.fullPath.startsWith("/user/crops")) {
      const cropId = to.path.split("/")[3];
      const companyId = to.query.companyId ?? undefined;
      if (cropId)
        if (to.fullPath.includes("edit")) {
          return {
            name: "crop-edit",
            params: { cropId },
            query: { companyId },
          };
        } else {
          return {
            name: "crop-log",
            params: { cropId },
            query: { companyId },
          };
        }
    }

    return { name: "crops-overview" };
  }

  const titleKey = [...to.matched].reverse().find((r) => r?.meta?.title)
    ?.meta.title;
  if (titleKey) {
    document.title = i18ng.t(titleKey);
  } else {
    document.title = "Log & Solve";
  }

  // routes with `meta: { requiresAuth: true }` will check for the users, others won't
  if (!to.meta.isPublic) {
    const [currentAuthUser, userInDb] = (await waitForUser()) as [
      User | null,
      DbUser | null,
    ];
    const isOnAuthPage = [
      "login",
      "signup",
      "asuser",
      "password-reset",
    ].includes(to.name?.toString() ?? "");
    // if the user is not logged in, redirect to the login page
    if (!currentAuthUser && !isOnAuthPage) {
      const { redirectTo } = to.query;
      return {
        name: "login",
        query: {
          // we keep the current path in the query so we can redirect to it after login
          // with `router.push(route.query.redirectTo || '/')`
          redirectTo: redirectTo ?? to.fullPath,
        },
      };
    }

    const isOnAgreementPage = ["agreement"].includes(to.name?.toString() ?? "");
    const agreementVersion = "01";
    const userAgreementVersion = userInDb?.agreement?.version;
    if (
      !isOnAgreementPage &&
      userAgreementVersion &&
      userAgreementVersion !== agreementVersion
    ) {
      return { name: "agreement" };
    }

    const can = checkAllowed(to.meta);

    if (!can) {
      return { name: "unauthorized" };
    }
  }

  try {
    const store = useCompanyStore();
    if (to.query.companyId && typeof to.query.companyId === "string") {
      store.setViewCompanyId(to.query.companyId);
    }
  } catch (error) {
    console.error(error);
  }
});

router.onError((error, to) => {
  if (
    error.message.includes("error loading dynamically imported module") ||
    error.message.includes("Unable to preload CSS for") ||
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    window.location = to.fullPath as unknown as Location;
  } else captureError(error, ErrorContext.UNKNOWN, ErrorType.UNHANDLED);
});

export default router;
