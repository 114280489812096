import { computed } from "vue";
import { sortBy } from "lodash";
import { useUserRolesStore } from "@/stores/user-roles";
import { storeToRefs } from "pinia";
import { upsertRole, deleteRole } from "@/queries/user-roles";

export function useUserRoles() {
  const userRolesStore = useUserRolesStore();
  const { userRoles, isLoading } = storeToRefs(userRolesStore);

  const userRolesForUsers = computed(() => {
    return sortBy(
      userRoles.value.filter((x) => !x.forThirdParty),
      "date",
    );
  });
  const userRolesForThirdParties = computed(() => {
    return sortBy(
      userRoles.value.filter((x) => x.forThirdParty),
      "date",
    );
  });

  return {
    userRoles,
    userRolesForUsers,
    userRolesForThirdParties,
    saveRole: upsertRole,
    deleteRole,
    isLoading,
  };
}
